<template>
  <!-- <b-card> -->
  <!-- form start -->
  <validation-observer ref="addForm" class="d-flex justify-content-center">
    <b-form class="col" @submit.prevent>
      <form-wizard
        ref="form_wizard"
        back-button-text="Précédent"
        nextButtonText="Suivant"
        color="#27295c"
        class="steps-transparent"
        @tabIndexChanged="activeTabIndex"
        :validateOnBack="true"
        :title="null"
        :subtitle="null"
        :startIndex="startIndex"
      >
        <!-- Informations générales tab start -->
        <tab-content
          title="Informations générales"
          :before-change="validationFormInfoGenerale"
        >
          <validation-observer ref="infoGeneraleRules" tag="form">
            <b-row class="mb-3">
              <!-- Nom association start -->
              <b-form-group class="col mr-2">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <label for="name">Nom Association</label>
                  <b-form-input
                    v-model="associationForm.etape1.nameAssociation"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nom Association"
                    @click="resetErrorInputNameAssociation"
                    trim
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : errorsNameAssociation
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Nom association end -->

              <!-- Code association start -->
              <b-form-group class="col mr-2">
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="required"
                >
                  <label for="code">Code Association</label>
                  <b-form-input
                    v-model="associationForm.etape1.codeAssociation"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Code Association"
                    @click="resetErrorInputCodeAssociation"
                    trim
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : errorsCodeAssociation
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Code association end -->
            </b-row>
            <hr />

            <b-row>
              <!-- associes start -->
              <div class="col">
                <div class="mb-1">
                  <b-card-text><strong>Associés :</strong></b-card-text>
                </div>

                <b-row
                  v-for="(item, index) in associationForm.etape1.associes"
                  :key="index"
                  ref="row"
                >
                  <b-form-group class="col-11">
                    <validation-provider
                      #default="{ errors }"
                      name="name_associes"
                      rules="required"
                    >
                      <label for="nomAssocies">Nom & Prénoms</label>
                      <b-form-input
                        id="nomAssocies"
                        v-model="item.name"
                        @input="checkNomAssocies(index)"
                        placeholder="Nom & Prénoms"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{
                        errorCheckNomAssocies[index]
                          ? errorCheckNomAssocies[index]
                          : ""
                      }}</small>
                      <small class="text-danger">{{
                        errors[0]
                          ? errors[0]
                          : item.errorNomAssocies
                          ? item.errorNomAssocies
                          : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Remove Button start -->
                  <div class="mt-1 mr-1" v-if="showRemoveItemAssocies > 1">
                    <b-button
                      variant="outline-danger"
                      class="p-0 mt-2"
                      @click="removeItemAssocies(index)"
                    >
                      <feather-icon icon="XIcon" />
                      <span></span>
                    </b-button>
                  </div>
                  <!-- Remove Button end -->
                </b-row>

                <!-- Add Button start -->
                <b-button
                  size="sm"
                  variant="primary"
                  :disabled="disabledButtonAssocies"
                  @click="repeateAgainAssocies()"
                  class="my-1"
                >
                  <b-icon-plus></b-icon-plus>
                  AJOUTER
                </b-button>
                <!-- Add Button start -->
              </div>
              <!-- associes end -->

              <!-- acheteur start -->
              <div class="col ml-1">
                <div class="mb-1">
                  <b-card-text><strong>Acheteurs :</strong></b-card-text>
                </div>
                <b-row
                  v-for="(item, index) in associationForm.etape1.acheteurs"
                  :key="index"
                  ref="row"
                >
                  <b-form-group class="col-11">
                    <validation-provider
                      #default="{ errors }"
                      name="name_acheteur"
                      rules="required"
                    >
                      <label for="nomAcheteur">Nom & Prénoms</label>
                      <b-form-input
                        id="nomAcheteur"
                        v-model="item.name"
                        placeholder="Nom & Prénoms"
                        @input="checkNomAcheteur(index)"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{
                        errorCheckNomAcheteur[index]
                          ? errorCheckNomAcheteur[index]
                          : ""
                      }}</small>
                      <small class="text-danger">{{
                        errors[0]
                          ? errors[0]
                          : item.errorNomAcheteur
                          ? item.errorNomAcheteur
                          : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Remove Button start -->
                  <div class="mt-1 mr-1" v-if="showRemoveItemAcheteur > 1">
                    <b-button
                      variant="outline-danger"
                      class="p-0 mt-2"
                      @click="removeItemAcheteur(index)"
                    >
                      <feather-icon icon="XIcon" />
                      <span></span>
                    </b-button>
                  </div>
                  <!-- Remove Button end -->
                </b-row>

                <!-- Add Button start -->
                <b-button
                  size="sm"
                  variant="primary"
                  :disabled="disabledButtonAcheteur"
                  @click="repeateAgainAcheteur"
                  class="my-1"
                >
                  <b-icon-plus></b-icon-plus>
                  AJOUTER
                </b-button>
                <!-- Add Button end -->
              </div>
              <!-- Acheteur end -->
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- Informations générales tab end -->
        <tab-content title="Compte" :before-change="validationFormInfoGenerale">
        </tab-content>
      </form-wizard>
    </b-form>
  </validation-observer>
  <!-- form end -->
  <!-- </b-card> -->
</template>

<script>
import { mapState } from "vuex";
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
import { ModelListSelect } from "vue-search-select";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import Cleave from "vue-cleave-component";
import {
  BCard,
  BCardText,
  BAlert,
  BModal,
  BButton,
  BIconPlus,
  BIconPencilFill,
  BIconTrashFill,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BInputGroup,
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BFormDatalist,
  BCollapse,
  BFormInvalidFeedback,
  BIconArrowClockwise,
  BIconCircleFill,
  BTableSimple,
  BTbody,
  BThead,
  BTh,
  BTd,
  BTr,
  BTfoot,
} from "bootstrap-vue";

export default {
  components: {
    BTableSimple,
    BTbody,
    BThead,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BCard,
    BModal,
    BCardText,
    Cleave,
    ModelListSelect,
    BFormDatalist,
    BAlert,
    BCardCode,
    BButton,
    EmptyList,
    BIconPlus,
    BIconPencilFill,
    BIconTrashFill,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BTable,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormSelectOption,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BIconCircleFill,
    BIconArrowClockwise,
    FormWizard,
    TabContent,
  },

  data() {
    return {
      etatButton: true,
      etatButtonEdit: true,
      add: false,
      empty: true,
      required,
      isOperating: false,
      integer,
      alpha,
      alphaDash,
      nameState: null,
      // totalRows: 1,
      perPage: 5,
      currentPage: 1,
      filter: null,
      pageOptions: [5, 10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      devisePrincipal: "",
      etatButtonVehiculeStock: true,
      startIndex: 0,
      activeEtape: 0,
      devises: [],
      clientsList: [],
      associesList: [],
      marquesList: [],
      couleursList: [],
      associations: [],
      editedFormVehiculeStock: {
        id: 0,
        marque: "",
        numero_chassis: "",
        couleur: "",
        annee: "",
        boite: "",
        prix_achat: "",
        prix_transp: "",
        frais: "",
        montant_vente: "",
        reste_payer: "",
        client: "",
      },
      modalEditFormVehiculeStock: false,
      vehiculeStockList: [],
      headers: [
        {
          key: "marque",
          label: "Marque",
          sortable: true,
        },
        {
          key: "numero_chassis",
          label: "Chassis",
          sortable: true,
        },
        {
          key: "couleur",
          label: "Couleur",
          sortable: true,
        },
        {
          key: "annee",
          label: "Année",
          sortable: true,
        },
        {
          key: "boite",
          label: "Boite",
          sortable: true,
        },
        {
          key: "prix_achat",
          label: "Achat",
          sortable: true,
        },
        {
          key: "prix_transp",
          label: "Transport",
          sortable: true,
        },
        {
          key: "frais",
          label: "Frais",
          sortable: true,
        },
        {
          key: "montant_vente",
          label: "Montant vente",
          sortable: true,
        },
        {
          key: "reste_payer",
          label: "Reste à payer",
          sortable: true,
        },
        {
          key: "client",
          label: "Client",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: "width: 120px",
        },
      ],
      boite_vitesses: ["Manuel", "Automatique"],
      numberFormat: {
        numeral: true,
        delimiter: " ",
        numeralThousandsGroupStyle: "thousand",
      },
      associationForm: {
        etape: 0,
        etape1: {
          nameAssociation: "",
          codeAssociation: "",
          associes: "",
          acheteurs: "",
        },
        etape2: {
          compte: [],
          montantCaisse: "",
        },
        etape3: {
          carte_importateur: "",
          frais: "",
          chargeurs: "",
        },
        etape4: {
          vehiculeStock: "",
        },
        etape5: {
          capitalTotal: "",
          capital_associes1: "",
          capital_associes2: "",
          capital_associes3: "",
          capital_associes4: "",
        },
      },
      compteAcheteur: [
        {
          libelle_compte: "",
          nom_acheteur: "",
          solde_initial: "",
          devise: "",
          errorsLibelleCompteAcheteur: "",
          errorsNomAcheteur: "",
          errorsSoldeInitialAcheteur: "",
          errorsDeviseAcheteur: "",
        },
      ],
      compteAssocies: [
        {
          libelle_compte: "",
          nom_associe: "",
          solde_initial: "",
          devise: "",
          errorsLibelleCompteAssocies: "",
          errorsNomAssocies: "",
          errorsSoldeInitialAssocies: "",
          errorsDeviseAssocies: "",
        },
      ],
      itemsAssocies: [
        {
          nom_associe: "",
          errorNomAssocies: "",
        },
      ],
      itemsAcheteurs: [
        {
          nom_acheteur: "",
          errorNomAcheteur: "",
        },
      ],
      itemsChargeurs: [
        {
          chargeur_name: "",
          devise: "",
          montant: "",
          errorsChargeurName: "",
          errorsMontant: "",
        },
      ],

      itemVehiculeStock: {
        marque: "",
        numero_chassis: "",
        couleur: "",
        annee: "",
        boite: "",
        prix_achat: "",
        prix_transp: "",
        frais: "",
        montant_vente: "",
        reste_payer: "",
        client: "",
      },

      nextTodoIdAssocies: 2,
      nextTodoIdAcheteur: 2,
      disabledButtonAssocies: false,
      disabledButtonAcheteur: false,
      showRemoveItemAssocies: 1,
      showRemoveItemAcheteur: 1,
      errorCheckNomAssocies: [],
      errorCheckNomAcheteur: [],
      errorsNameAssociation: null,
      errorsCodeAssociation: null,
      errorsMontantCaisse: null,
      errorsCarteImportateur: null,
      errorsFrais: null,
      errorsClient: null,
      errorNumero_chassis: null,
      listeChargeurs: [],
      hiddenLoader: false,

      association: {},
    };
  },
  created() {
    this.showAssociation();
    // this.getDevisesList();
  },

  methods: {
    showAssociation() {
      let id = this.$router.currentRoute.params.id;
      this.$http
        .get("/associations/" + id)
        .then((res) => {
          this.association = res.data.data;
          this.activeEtape = this.association.etape - 1;

          this.associationForm.etape1 = {
            nameAssociation: this.association.name,
            codeAssociation: this.association.code,
            associes: this.association.acheteurs_associes.filter(
              (_ass) => _ass.type == "associe"
            ),
            acheteurs: this.association.acheteurs_associes.filter(
              (_ass) => _ass.type != "associe"
            ),
            etape: 0,
          };
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e);
        });
    },

    activeTabIndex(data) {
      this.activeEtape = data;

      if (data == 1) {
        this.associationForm.etape1.associes = this.itemsAssocies;
        this.associationForm.etape1.acheteurs = this.itemsAcheteurs;
      }

      if (data == 2) {
        this.associationForm.etape2.compte.push(this.compteAcheteur);
        this.associationForm.etape2.compte.push(this.compteAssocies);
      }

      if (data == 3) {
        this.associationForm.etape3.chargeurs = this.itemsChargeurs;
      }

      if (data == 4) {
        this.associationForm.etape4.vehiculeStock = this.vehiculeStockList;
      }

      if (data == 5) {
        this.associationForm.etape5.capitalTotal = this.$thousandFormater(
          this.associationForm.etape5.capitalTotal
        );
        this.associationForm.etape5.capital_associes1 = this.$thousandFormater(
          this.associationForm.etape5.capital_associes1
        );
        this.associationForm.etape5.capital_associes2 = this.$thousandFormater(
          this.associationForm.etape5.capital_associes2
        );
        this.associationForm.etape5.capital_associes3 = this.$thousandFormater(
          this.associationForm.etape5.capital_associes3
        );
        this.associationForm.etape5.capital_associes4 = this.$thousandFormater(
          this.associationForm.etape5.capital_associes4
        );
      }
    },
    validationFormInfoGenerale() {
      return new Promise((resolve, reject) => {
        this.$refs.infoGeneraleRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    // Associes start
    repeateAgainAssocies() {
      this.showRemoveItemAssocies = this.showRemoveItemAssocies + 1;

      this.associationForm.etape1.associes.push({
        id: (this.nextTodoIdAssocies += this.nextTodoIdAssocies),
        nom_associe: "",
      });
    },

    removeItemAssocies(index) {
      this.showRemoveItemAssocies = this.showRemoveItemAssocies - 1;
      this.associationForm.etape1.associes.splice(index, 1);
    },

    checkNomAssocies(index) {
      if (this.associationForm.etape1.associes[index].errorNomAssocies) {
        this.associationForm.etape1.associes[index].errorNomAssocies = "";
      }

      let enteredNomAssocies =
        this.associationForm.etape1.associes[index].nom_associe;

      for (let i = 0; i < this.associationForm.etape1.associes.length; i++) {
        const item = this.associationForm.etape1.associes[i];

        if (
          i !== index &&
          item.nom_associe.toUpperCase() == enteredNomAssocies.toUpperCase()
        ) {
          this.errorCheckNomAssocies[index] =
            "Ce Nom est déjà choisi au niveau de la ligne " + (i + 1);
          this.disabledButtonAssocies = true;

          i = this.associationForm.etape1.associes.length;
        } else {
          this.disabledButtonAssocies = false;

          if (this.errorCheckNomAssocies.length) {
            this.errorCheckNomAssocies = [];
          }
        }
      }
    },

    // Acheteur start
    repeateAgainAcheteur() {
      this.showRemoveItemAcheteur = this.showRemoveItemAcheteur + 1;

      this.associationForm.etape1.acheteurs.push({
        id: (this.nextTodoIdAcheteur += this.nextTodoIdAcheteur),
        nom_acheteur: "",
      });
    },

    removeItemAcheteur(index) {
      this.showRemoveItemAcheteur = this.showRemoveItemAcheteur - 1;
      this.associationForm.etape1.acheteurs.splice(index, 1);
    },

    checkNomAcheteur(index) {
      if (this.associationForm.etape1.acheteurs[index].errorNomAcheteur) {
        this.associationForm.etape1.acheteurs[index].errorNomAcheteur = "";
      }

      let enteredNomAcheteur =
        this.associationForm.etape1.acheteurs[index].nom_acheteur;

      for (let i = 0; i < this.associationForm.etape1.acheteurs.length; i++) {
        const item = this.associationForm.etape1.acheteurs[i];

        if (
          i !== index &&
          item.nom_acheteur.toUpperCase() == enteredNomAcheteur.toUpperCase()
        ) {
          this.errorCheckNomAcheteur[index] =
            "Ce Nom est déjà choisi au niveau de la ligne " + (i + 1);
          this.disabledButtonAcheteur = true;

          i = this.associationForm.etape1.acheteurs.length;
        } else {
          this.disabledButtonAcheteur = false;

          if (this.errorCheckNomAcheteur.length) {
            this.errorCheckNomAcheteur = [];
          }
        }
      }
    },
    // Acheteur end

    resetErrorInputNameAssociation() {
      if (this.errorsName) {
        this.errorsName = null;
      }
    },

    resetErrorInputCodeAssociation() {
      if (this.errorsCode) {
        this.errorsCode = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.input-group-append {
  .input-group-text {
    background-color: rgb(201, 201, 201);
  }
}

.block-text .card-body {
  padding-bottom: 0px;
}

.repeater-form {
  overflow: hidden;
  transition: 5s height;
}
</style>
